@import "tabulator-tables/dist/css/tabulator.min.css";
@import "react-checkbox-tree/lib/react-checkbox-tree.css";
@import "/src/assets/css/tabulator.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,600;1,9..144,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-inter;
  }
  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply !bg-slate-200 dark:!bg-slate-400;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-sm !bg-slate-400 dark:!bg-slate-600;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply !bg-gray-800;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* .scrollbar-hidden::-ms-reveal {
    display: none;
    filter: invert(100%);
  } */

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent;
  }
  input {
    @apply !text-gray-700 dark:!text-white;
  }
  input[type="file"]::file-selector-button {
    @apply !text-gray-700 dark:bg-slate-600 dark:!text-white;
  }

  .accordion-desc a {
    @apply font-bold underline underline-offset-2;
  }
}

.formik-field {
  @apply w-full rounded-lg border-2 py-1 px-4 focus:border-gray-400 focus:outline-none;
}

.formik-error {
  @apply mx-2 text-xs font-semibold text-red-400;
}

.customized-booking-rows {
  @apply !text-blue-600 dark:!text-green-600;
}

/* Tabualtor Starts Here */
.tabulator {
  @apply bg-transparent !important;
}

.tabulator-col-title {
  @apply !text-slate-900 dark:!text-white;
}

.tabulator-col {
  @apply !bg-white dark:!bg-slate-900;
}

/* .tabulator .tabulator-header,
.tabulator-header-contents,
.tabulator-headers,
.tabulator-col {
  height: 3rem; 
} */
.tabulator.tabulator-header.tabulator-col.tabulator-col-content {
  @apply p-4;
}
.tabulator .tabulator-tableholder .tabulator-table {
  @apply bg-white font-medium text-slate-900 dark:!bg-slate-700 dark:text-white;
}
.tabulator-row.tabulator-row-even {
  @apply bg-white text-slate-900 dark:bg-slate-800 dark:text-white;
}
.tabulator-row.tabulator-row-odd {
  @apply bg-[#f1f8fb] text-black dark:bg-slate-900 dark:text-white;
}
.tabulator .tabulator-footer .tabulator-paginator {
  @apply text-slate-600 dark:text-white;
}
.tabulator .tabulator-footer .tabulator-page.active {
  @apply dark:bg-lime-100 dark:text-slate-900;
}

.tabulator-page-size {
  @apply dark:bg-slate-900;
}

/* .tabulator-row-even {
  @apply bg-jw-green/10 !important;
} */

.tabulator-cell {
  /* @apply border-x !border-gray-200; */
}

.tabulator .tabulator-header {
  @apply border;
}

.tabulator .tabulator-footer {
  @apply bg-white text-gray-800 dark:bg-slate-800 dark:text-white;
}

.tabulator .tabulator-footer .tabulator-footer-contents {
  @apply flex w-full gap-3 overflow-x-auto;
}

.tabulator-row .tabulator-cell {
  padding: 16px 4px;
}

.tabulator .tabulator-row {
  @apply hover:!bg-[#D7F2FD] dark:hover:!bg-slate-700;
}

.innerTable .tabulator-col {
  @apply !bg-gray-200;
}

.innerTable .tabulator-col-title {
  @apply !text-gray-600;
}

.innerTable .tabulator {
  @apply !border-0;
}

.innerTable .tabulator .tabulator-row {
  @apply hover:!bg-gray-100;
}

.react-checkbox-tree label {
  @apply p-2 px-4 text-xl text-black dark:text-white;
}

/* Light mode styles */
.react-select__control {
  @apply border-gray-300 bg-white text-black dark:bg-slate-800 dark:text-white;
}
.react-select__menu {
  @apply bg-white text-black;
}
.react-select__single-value,
.react-react-select__input-container .react-select__input {
  @apply !text-black;
}
.react-select__option--is-focused {
  /* @apply !bg-slate-500 !text-white dark:!bg-slate-600 dark:!text-white; */
  @apply !bg-slate-100;
}
.react-select__option--is-selected {
  /* @apply bg-blue-400 text-white; */
  /* @apply !bg-transparent; */
  @apply !bg-slate-200;
}
.react-select__multi-value {
  @apply dark:bg-slate-900 dark:text-white;
}
.react-select__multi-value__label,
.react-select__multi-value__remove {
  @apply rounded-none border border-slate-400 bg-slate-100 px-8 font-bold text-gray-900 dark:border-slate-600 dark:bg-slate-900 dark:text-white;
}
.react-select__multi-value__remove {
  @apply border-l-0 hover:bg-slate-100 dark:hover:bg-slate-900;
}
.react-tel-input .form-control {
  @apply !bg-white text-gray-800 dark:!bg-slate-800 dark:text-white;
}
.react-tel-input .flag-dropdown {
  @apply !bg-white dark:!bg-slate-800;
}
.react-tel-input .flag-dropdown.open {
  @apply !bg-white dark:!bg-slate-800;
}
.country-list {
  @apply dark:!bg-slate-900;
}
.country-list .country {
  @apply hover:!bg-gray-200 hover:!text-black dark:!bg-slate-900 dark:text-white dark:hover:!bg-slate-600 dark:hover:!text-white;
}
.country-list .dial-code {
  @apply dark:text-white;
}

.country-list .country.highlight {
  @apply dark:!bg-blue-500 dark:text-white;
}
.react-tel-input .country-list .search,
.react-tel-input .country-list .search input {
  @apply bg-white text-gray-600 dark:bg-slate-900 dark:text-white;
}

/* react date range dark mode */
.rdrCalendarWrapper {
  @apply !bg-white dark:!bg-slate-900  dark:!text-white;
}

.rdrDateDisplayWrapper {
  @apply hidden;
}
.rdrMonthAndYearWrapper {
}
.rdrMonthAndYearPickers select {
  @apply !bg-gray-100 !text-gray-800 dark:!bg-slate-800 dark:!text-white;
}

.rdrMonth {
  @apply !text-white;
}

.rdrDayNumber span {
  @apply dark:!text-white;
}
.rdrDayNumber span:focus {
  background-color: #555;
}
.rdrSelected,
.rdrInRange {
  /* background-color: #555; */
}
.rdrDayToday .rdrDayNumber span:after {
  /* border: 1px solid #fff; */
}

.ck.ck-editor__main > .ck-editor__editable {
  @apply !bg-transparent;
}

.card-header-background {
  @apply bg-[#D7F2FD];
}
/* .ck.ck-toolbar {
  @apply !bg-white !text-gray-900 dark:!bg-slate-900 dark:!text-white;
}
.ck-rounded-corners .ck.ck-button,
.ck-rounded-corners a.ck.ck-button,
.ck.ck-button.ck-rounded-corners,
a.ck.ck-button.ck-rounded-corners {
  @apply bg-white text-gray-900 dark:!bg-transparent dark:!text-white dark:hover:bg-slate-700;
} */

/* .wave {
    animation: wave 5s ease-in-out infinite;
}

@keyframes wave  {
    from {
        d: path("M0.1,77.4L0.1,77.4c0.9,0,1.8,0,1.8,0c1.8,0,2.8,0,7,0s8.9,0,16.3,0c7.4,0,8.5,0,9.4,0s0.3,0,0.1,0 c-0.2,0,0.2,0,5.4,0s7.7,0,7.7,0c5.5,0,18.4,0,18.4,0c8.1,0,16.2,0,16.2,0c7.9,0,14.1,0,14.1,0c2.6,0,11.5,0,11.5,0 c8.2,0,16.2,0,16.2,0c3.4,0,9.6,0,12.2,0s10.4,0,10.4,0c2,0,4.6,0,4.6,0h-0.2c-3.1,0-6.5,0-6.5,0c-2.7,0-5.1,0-5.1,0 c-1.7,0-13.3,0-17.7,0c-5.7,0-10.6,0-10.6,0l-8.2,0l5.2,0c4.4,0,1.2,0-6,0s-14.8,0-18.3,0c-3.5,0,0.4,0,0.4,0l5.1,0 c-5,0-7.8,0-13.2,0c-6.8,0-11.2,0-11.2,0l-13.6,0c0,0-5.6,0-14.8,0c-5.3,0-9.1,0-9.7,0c0,0,0.1,0,0,0c-0.1,0-0.4,0-2.8,0 c-7.4,0-12.6,0-12.6,0c-5.7,0-11.6,0-11.6,0 M101,77.4c-3.5,0-4.6,0-4.6,0l-4.7,0c0,0-3.6,0,0,0c2.3,0,6.3,0,8.8,0 C100.6,77.4,100.8,77.4,101,77.4C103,77.4,103,77.4,101,77.4z");
    }to {
        d: path("M.3 80.3c1-.1 1.9 1.7 1.9 1.7 1.8 2.4 2.8 7.9 7 15.7s8.9 11.1 16.3 8.7 8.5-10.9 9.4-15.8.3-19.8.1-22.4.2-7.2 5.4-8.5 7.7 6.3 7.7 6.3c5.5 12.7 18.4 33.2 18.4 33.2 8.1 13.1 16.2 6.5 16.2 6.5C90.6 101.2 96.8 92 96.8 92c2.6 4 11.5 12.2 11.5 12.2 8.2 7.8 16.2-2.2 16.2-2.2 3.4-2.8 9.6-15.4 12.2-20.6s10.4-24.8 10.4-24.8c2-3.5 4.6-1.6 4.6-1.6l-.2-.8c-3.1-3.3-6.5-2.3-6.5-2.3-2.7.5-5.1 6.4-5.1 6.4-1.7 4.6-13.3 22.8-17.7 27.3-5.7 5.9-10.6 2.1-10.6 2.1l-8.2-5.4 5.2-9.3c4.4-7.8 1.2-15.8-6-19.6s-14.8 0-18.3 6 .4 13.6.4 13.6l5.1 8.8c-5 3.7-7.8 7.1-13.2 6.9-6.8-.4-11.2-7.7-11.2-7.7L51.8 58.1s-5.6-8.6-14.8-5.5c-5.3 1.7-9.1 6.4-9.7 11.9 0 0 .1 12.4 0 18.6s-.4 7.8-2.8 9.4c-7.4 5.1-12.6-4.1-12.6-4.1C6.3 78.8.4 79.6.4 79.6m100.9-9c-3.5 4.4-4.6 5.5-4.6 5.5L92 70.5s-3.6-4.1 0-8.4c2.3-2.6 6.3-2.7 8.8-.4l.4.4c2.1 2.5 2.1 6 .1 8.5z")
    }
} */

@layer components {
  @keyframes marquee {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  .animate-marquee {
    animation: marquee 10s linear infinite;
  }
  .animate-marquee:hover {
    animation-play-state: paused;
  }

  .wrapper {
    @apply h-full rounded-lg bg-white px-4 text-gray-700 dark:bg-slate-900 dark:text-white;
    min-height: 88vh;
  }
  @media (min-width: 1024px) {
    /* lg */
    .wrapper {
      @apply h-full rounded-lg bg-white px-4 text-gray-700 dark:bg-slate-900 dark:text-white;
      min-height: 88vh;
    }
  }
  @media (min-width: 1280px) {
    /* xl */
    .wrapper {
      @apply h-full rounded-lg bg-white px-4 text-gray-700 dark:bg-slate-900 dark:text-white;
      min-height: 90vh;
    }
  }
  @media (min-width: 1536px) {
    /* 2xl */
    .wrapper {
      @apply h-full rounded-lg bg-white px-4 text-gray-700 dark:bg-slate-900 dark:text-white;
      min-height: 92vh;
    }
  }

  .btn-primary {
    @apply mb-1 rounded-md bg-[#0183c0] py-2 px-6 font-semibold capitalize tracking-wide text-white shadow-lg transition-all duration-300 hover:bg-[#0183c0]/95 hover:shadow-lg disabled:bg-[#0183c0]/80 disabled:text-white/50 disabled:shadow-none;
  }

  .btn-secondary {
    @apply rounded-full bg-jw-green-lighter py-2 px-12 font-semibold capitalize tracking-wide text-jw-green shadow-lg transition-all duration-300 hover:bg-jw-green-light hover:shadow-md disabled:text-white/50 disabled:shadow-none;
  }

  .banner-slider-dots {
    position: absolute;
    bottom: 0.5rem !important;
  }

  .banner-slider-dots li {
    margin: 0 !important;
  }

  .banner-slider-dots li button:before {
    color: #22577a !important;
  }
}

.search-slider-dots {
  bottom: -1rem !important;
}

.footer a:hover {
  transition: all 0.3s;
  color: #fff;
}

.text-16 {
  @apply text-[14px] !important;
}

.line-2-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-10-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 99;
  background: rgba(0, 0, 0, 0.25) !important;
}

.error-msg {
  @apply mx-2 text-xs font-semibold text-red-400;
}

.error {
  @apply bg-red-200 text-red-600;
}

.success {
  @apply bg-green-200;
}

.warning {
  @apply bg-orange-100 text-yellow-500;
}

input:-webkit-autofill {
  @apply bg-transparent !important;
}

/* ------------------------------------------------------ */

.package-detail-list p {
  @apply relative after:absolute after:top-2 after:-left-4 after:h-1 after:w-1 after:rounded-full after:bg-gray-700 after:content-[''];
}

.rdrSelected {
  @apply text-jw-green !important;
}

.field {
  @apply block w-full rounded-md border py-[7px] px-2 disabled:cursor-not-allowed dark:border-white dark:bg-transparent;
}

.itinerary-template figure {
  @apply mt-4 w-full pb-4;
}

.itinerary-template figure table {
  @apply w-full table-auto border text-center;
}

.itinerary-template figure table thead {
  @apply bg-gray-200;
}

.itinerary-template figure table th,
.itinerary-template figure table td {
  @apply border pb-4;
}

.itinerary-template figure table tr:nth-child(even) {
  @apply bg-gray-100;
}

.itinerary-template .inclusions p,
.itinerary-template .exclusions p,
.itinerary-template .exclusions ul li,
.itinerary-template .inclusions ul li {
  @apply relative pl-4 after:absolute after:top-0 after:right-full after:h-auto after:w-auto after:-translate-y-1/2;
}

.itinerary-template .inclusions p,
.itinerary-template .inclusions ul li {
  @apply after:h-0 after:w-0 after:text-green-600 after:content-["\2714"];
}

.itinerary-template .exclusions p,
.itinerary-template .exclusions ul li {
  @apply after:h-0 after:w-0 after:text-red-600 after:content-["\2716"];
}

.itinerary-template ul {
  @apply list-none pl-4;
}

.itinerary-template ul li {
  @apply relative after:absolute after:right-full after:top-4 after:mr-2 after:h-[6px] after:w-[6px] after:rounded-full after:bg-gray-700 after:content-[''];
}

.editor .ck-content {
  @apply !px-6;
}

.editor .ck-content h2,
.editor .ck-content h3,
.editor .ck-content h4 {
  @apply !font-bold;
}

.editor .ck-content h2 {
  @apply text-3xl;
}

.editor .ck-content h3 {
  @apply text-2xl;
}

.editor .ck-content h4 {
  @apply text-xl;
}

@layer utilities {
  /* .scrollbar::-webkit-scrollbar {
    width: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #3d424f;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #1c2336;
    border-radius: 10vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #1c2336;
  } */
}

.responsiveWrapper {
  @screen 2xl {
    min-height: 7vh;
  }

  @screen xl {
    min-height: 8vh;
  }

  @screen lg {
    min-height: 93vh;
  }
}

/* -----------------------Sweet Alert - 2 Styling */

.swal2-confirm {
  background-color: #0183c0;
  color: white;
}

.swal2-deny {
  background-color: #0183c0;
  color: white;
}

.swal2-cancel {
  background-color: #ad3527;
  color: white;
}

.swal2-close {
  color: black;
  font-weight: bold;
}

.swal2-title {
  color: black;
  font-weight: bold;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
