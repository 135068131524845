.recommended-trip-card {
  @apply relative min-w-[45%] shrink-0 grow basis-[30%] rounded-lg shadow-[2px_4px_8px_rgba(16,79,24,0.1),4px_4px_8px_rgba(0,0,0,0.2)] lg:min-w-0 lg:basis-[15%];
}

.recommended-trip-card img {
  @apply h-60 w-full rounded-lg object-cover lg:h-64;
}

.react-dual-listbox select option {
  @apply m-2 rounded-sm bg-blue-200 p-4 font-bold;
}
